@charset "utf-8";
/* CSS Document */

/*
@media (min-width: 677px) and (max-width: 1000px){
    .home-criteres .item.title{width: 100%; background: none; padding: 30px; margin: 0 auto}
    .home-criteres .item.contentbloc{width: 33.33333%; margin: 0 auto}
}*/

@media (max-width: 1550px){
  div.views-exposed-form{ padding: 0;}
}

@media (max-width: 1290px){
  .view-produits .view-filters{width: 30%;}
  .view-produits .view-content{width: 70%;}
  .view-produits .views-row{width: 50%;}
  .view-produits .views-row:nth-child(3n+1){border-left: none;}
  .view-produits .views-row:nth-child(odd){border-left: 2px solid #000; }
  article h1, body.page-nos-actualites .en-tete h1, body.page-nos-marques .diaporama .title{font-size: 35px;}

}

@media (max-width: 900px){
  #frise-histoire .field-collection-view:nth-child(even) .description{order: 0; justify-content: flex-start}
  #frise-histoire .field-collection-view .wrap{flex-direction: column; padding: 40px 0;}
  .main-entreprise .field-collection-view .item .image, .main-entreprise .field-collection-view .item .description{flex: auto;}
  .main-entreprise .field-collection-view .item .description .annee{ font: 400 45px/40px 'Dancing Script', cursive;}
  #frise-histoire:after{display: none;}
  #frise-histoire .field-collection-view .description .content{max-width: 100%;}
  #frise-histoire .field-collection-view .image{padding: 0}
  body footer #pre-footer ul li:nth-child(even){margin-left: 0;}
  .view-produits .views-row:nth-child(3n+1){border-left: none;}
  .view-produits .views-row:nth-child(odd){border-left: 2px solid #000; }

  .view-produits .view-filters{ min-height: auto;padding-bottom: 40px; width: 100%;}
  .view-produits .views-row:first-child, .view-produits .views-row:nth-child(2){border-top: 2px solid #000; }
  .view-produits .view-content{width: 100%; flex: 1 0 auto;}
  .view-produits div.title-form{float: none;}
  .views-exposed-form .views-exposed-widget{float: left;width: 31%; padding-top: 40px; margin: 0 1%;}
  .views-exposed-widgets label:first-child{ margin-top: 0; font-size: 37px; }
  .view-produits div.title-form{ display: inline-block; margin-bottom: 0;}
  .page-nos-actualites .view-actualites .views-row{ width: 100%;}
  .page-nos-actualites .view-actualites .views-row .image{width: 100%;flex: inherit; text-align: center; }
  .page-nos-actualites .view-actualites .views-row .image img{width: auto; max-width: 220px; }
  span.field-content div.itemmarque div.bottom div.description .image{order: -1;}
  span.field-content div.itemmarque div.bottom div.description div.texte{padding: 0 15px;}
  span.field-content div.itemmarque div.top{height: auto;}
  span.field-content div.itemmarque div.bottom div.meta ul, span.field-content div.itemmarque div.bottom div.meta p{padding: 15px;}
  span.field-content div.itemmarque div.bottom div.description p.more{text-align: center;}
  span.field-content div.itemmarque div.top div.logo img{ width: 160px;}
}


@media all and (max-width: 1435px){
  .wrap{ width: 96%;}
  article.mag .contentmag .blocmag .caption h2{margin-bottom: 20px;}
  article.mag .saprestimag .menusapresti{max-width: 320px;}
  .node-type-produit .owl-carousel{height: auto;}
  .aimerez-aussi h2{ background-position: right 5% center;}
  .home-criteres .item.title span.critere-name{font: 400 45px 'Dancing Script', cursive;}
  footer #pre-footer ul li{width: 48%;margin: 0 0 15px 0;}
  footer #pre-footer ul li:nth-child(even){margin-left: 4%; }
}

@media (max-width: 1189px){
  .button-reset p{padding: 50px 0px 50px 0px;display: inline-block;}
  .home-criteres .item.title{ flex: none; width: 100%; background-size: 20px;
    text-align: center;
  }
  .diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption .chapo{ top: 120px;
  }
  .diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption h1{font-size: 65px; }
  header{padding: 0 15px;}
  header .header-left nav{display: none;}
  header .trigger-menu{display: block;}
  header .menu-secondaire{display: none;}
  header .langue{margin-right: 50px;}
  article h1, .slide-accueil .field-collection-view .title, body.page-nos-marques .slides .title{ font-size: 65px;}
  article .main-content{padding-top: 60px;}
  article .main-content .wrap{padding: 0 50px;}
  .page-nos-actualites .view-actualites .views-row{width: 96%; }
  article.mag .contentmag .blocmag .caption h2:before, article.mag .contentmag .blocmag .caption h2:after{display: none;}
  article.mag .contentmag .content .bottomleft .blocmag{width: 100%; }
  article.mag .contentmag .content > div{flex: 1 0 auto; width: 100%; }
  article.mag .contentmag .content .left{flex: 1 0 0%; }
}

@media (max-width: 900px){
  body.node-type-page article .en-tete, body.node-type-webform article .en-tete, body.node-type-actualite article.actualites .en-tete, body.page-nos-actualites .en-tete{ min-height: 350px;}
  footer #pre-footer ul li{width: 100%; }
  span.field-content div.itemmarque div.bottom div.description h2{width: 100%;}
  span.field-content div.itemmarque div.bottom div.description p{ width: 100%;line-height: 20px;}
  body.node-type-webform .main-content .wrap{padding: 0; }
  .fiche-produit .details-slides, .fiche-produit .details-info{width: 100%;}
  span.field-content div.itemmarque div.bottom div.description p.more a{padding: 10px 35px 10px 0;
    display: inline-block; font-size: 29px;}
  .diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption .chapo{width: 95%; }
  .aimerez-aussi h2{
    width: 100%;
    background-size: 13px;
    text-align: center;
  }
  .item-aimerez-aussi{ border-top: 1px solid #000;}
  .aimerez.owl-carousel{width: 100%; max-width: 100%; }
  .fiche-produit .details-info{ padding: 50px 0px;}
}

@media (max-width: 768px){
  .main-entreprise .field-collection-view .item{ padding-top: 100px;}
  .main-entreprise .field-collection-view .item .description .annee, .main-entreprise .field-collection-view .item .description .titre{position: absolute; top: 40px; right: 0; left: 0; margin: auto; text-align: center;}
  .main-entreprise .field-collection-view .item .description .titre{top: 100px; }
  .main-entreprise .field-collection-view .item .description .annee{ font-size: 31px;}
  article.mag .saprestimag .menusapresti{ margin-top: 0;}
  article.mag .saprestimag > div{ flex: 1 0 auto; width: 100%;}
  article.mag .contentmag .blocmag .caption > *{ width: 100%;}
  .home-criteres .item.contentbloc.home-marques{background-size: contain;}
  article.mag .saprestimag .menusapresti ul.menu li.leaf, article.mag .saprestimag .menusapresti ul.menu li.active-trail{
    text-align: left;
  }
  article.mag .saprestimag .menusapresti{
    max-width: 350px;
  }
  .search .wrap .frmSearch #search-box{
    width: 270px;
  }
}

@media (max-width: 700px){
  .main-entreprise #valeurs{padding: 50px 0 100px; text-align: center;margin-top: 40px;}
  .main-entreprise #valeurs h3{}
  article.mag .saprestimag .menusapresti, article.mag .saprestimag > div{width: 100%; flex: 1 0 auto;}
  article.mag .saprestimag .menusapresti{max-width: 100%; }
  article.mag .contentmag .bottomcontent .blocmag{display: block;}
  article.mag .contentmag .bottomcontent .blocmag .image{margin-right: 0; }
  article.mag .contentmag .blocmag:hover .caption > *{width: 100%;}
  .view-produits .view-filters{ padding: 0 10px;}
  .views-exposed-widgets label:first-child{ font-size: 25px;}
  .form-item label{margin-left: 5px; font-size: 12px;}
  .fiche-produit .details-info .accroche, .fiche-produit .details-info .description{max-width: 90%;}
}


@media (max-width: 676px){
  footer .wrap .flex > div{ flex: 1 0 auto; width: 100%;}
  footer #pre-footer ul li{margin: 0;}
  .home-criteres{ padding: 0;}
  span.field-content div.itemmarque div.bottom div.description{display: block;}

  .home-criteres .item.title{width: 100%; background: none; margin: 0 auto; padding: 20px 10%;}
  .home-criteres .item.contentbloc{ width: 50%; margin-bottom: 0.2rem; height: 260px; max-width: 48%;}
  .home-criteres .item span.title{ font-size: 17px;}
  footer .wrap .flex #pre-footer{margin-bottom: 45px;}
  footer .right .social{margin-bottom: 12px;}
  article .en-tete .menu-ancres ul li + li{margin-left: 0;}
  article .en-tete .menu-ancres ul li{margin-bottom: 15px;}
  article .en-tete .menu-ancres{ bottom: 0;}
  article .en-tete .menu-ancres ul li a{padding: 13px 30px; }
  .view-produits .views-row{width: 50%;}
  span.field-content div.itemmarque div.top{max-height: none;display: block;}
  span.field-content div.itemmarque div.top *{ flex: 1; width: 100%;}
  span.field-content div.itemmarque div.top .logo{margin: 35px auto;}

  span.field-content div.itemmarque div.bottom div.meta, span.field-content div.itemmarque div.bottom div.description{flex: 1 0 auto; width: 100%;}
  span.field-content div.itemmarque div.bottom div.description{padding-top: 60px; position: relative;}
  span.field-content div.itemmarque div.bottom div.meta li{ margin-right: 5px; margin-left: 0;}
  span.field-content div.itemmarque div.bottom div.meta li + li{margin-left: 0; }
  span.field-content div.itemmarque div.bottom div.meta li, span.field-content div.itemmarque div.bottom div.meta p{ display: inline-block; line-height: 25px;}

  span.field-content div.itemmarque div.bottom div.description p.more{position: absolute; bottom: 30px; margin-top: 0; right: 30px; text-align: right; }
  span.field-content div.itemmarque div.bottom div.description div.image{ text-align: center;}
  span.field-content div.itemmarque div.bottom div.description{padding-bottom: 100px;padding-top: 0;}
  span.field-content div.itemmarque div.bottom{display: block;}
  span.field-content div.itemmarque div.bottom div.description p.more a{ font-size: 23px;}

  /* span.field-content [id="Auvernou"] div.top div.logo img{
       height:55px;
       width:auto !important;
   }
   span.field-content [id="Sapresti Traiteur"] div.top div.logo img{
       height: 70px;
       width: auto !important;
   }*/

}

@media all and (max-width: 535px){
  article.mag .contentmag .content .left, article.mag .contentmag .content .right{flex: 1 0 auto;width: 100%; }
  .views-exposed-form .views-exposed-widget{ width: 100%; padding-top: 20px;}
  .view-produits .view-filters{ padding-bottom: 0;}
  footer{ padding: 25px 0 5px;}
  footer .wrap .flex #pre-footer{margin-bottom: 15px; }
  footer #pre-footer ul li a, footer #pre-footer ul li span{text-transform: none; }
  footer #pre-footer ul li{display: inline-block; width: auto;}
  footer #pre-footer ul li + li::before{ padding-right: 5px; content: '|';}
  footer #pre-footer ul li + li, body footer #pre-footer ul li:nth-child(even), footer #pre-footer ul li:nth-child(3n+1){ margin-left: 5px;}
  .fiche-produit .details-info .title{width: auto; }
  .view-produits .view-content .views-row .bloc_produit .bloc_text .readmore{display: none;}
  .node-type-produit .bandeau .owl-carousel img{width: auto; max-width: none; }
  article .en-tete{ min-height: 380px;}
  .node-type-produit .bandeau .descriptionmarque{display: none;}
  body.node-type-page-entreprise article .en-tete .filterbandeau, body.node-type-page-entreprise article .en-tete .filterbandeau, .node-type-produit .bandeau .owl-carousel{
    height: 380px;
  }
  article.mag .contentmag .blocmag > img{ min-height: 500px;
    object-fit: cover;
  }
  body.node-type-page article .en-tete, body.node-type-webform article .en-tete, body.node-type-actualite article.actualites .en-tete, article .en-tete .filterbandeau, .bandeau .filterbandeau{ height: 380px;}
  .main-entreprise .field-collection-view .item .description{padding: 15px; }
  #frise-histoire:after{display: none;}
  body.node-type-page .search, body.node-type-actualite .search, body.node-type-webform .search{ top: 28px;}
  header{ height: auto;}
  article form .form-item{width: 100%; }
  article form .form-item:nth-child(even){ margin-left: 0;}
  .img-intro-left{
    float: none;
    margin: 0 0 15px 0;
    width: 100%;
  }

  body.node-type-page article .en-tete, body.node-type-webform article .en-tete, body.node-type-actualite article.actualites .en-tete, body.page-nos-actualites .en-tete{ min-height: 380px;}
  .home-criteres .item.contentbloc{ max-width: 78%; width: 78%;}

  footer .wrap{ padding: 0 15px;}
  footer .right .menu-footer ul{text-align: center;}
  footer .right .menu-footer ul li + li{ margin-left: 5px;}
  footer .right .menu-footer ul li + li::before{ padding-right: 5px;}
  footer .wrap .flex #pre-footer ul.menu{text-align: center;}
  ul.menu li{ margin: 0;}
  .main-entreprise .field-collection-view:nth-child(even) .item{ background-position: bottom left;}

  .h1pagebase{font-size: 30px;}
  .page-nos-actualites .view-actualites .views-row .contenu h2{ font-size: 20px; line-height: 22px;}
  .page-nos-actualites .view-actualites .views-row .contenu{line-height: 20px; text-align: justify; }

  article h2{
    font-size: 22px;
  }

  .diaporama{ height: 360px;}

  .img-intro-left img{
    width: 100%;
  }
  article .main-content{
    padding-top: 20px;
    margin-top: 30px;
  }

  article h1, .slide-accueil .field-collection-view .title, .page-nos-actualites .view-actualites .view-header h1, .diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption h1, body.page-nos-actualites .en-tete h1, body.page-nos-actualites .en-tete h1, body.page-nos-marques .slides .title, body.page-nos-marques .slides .title{
    font-size: 30px; padding: 0 10px;
  }

  .diaporama.mag .slide-accueil .slides .field-collection-container .owl-stage-outer .owl-stage .owl-item .field-collection-view .caption .chapo{display: none;}
  .diaporama .slide-accueil .logos{display: none !important;}
  article .main-content .wrap{
    padding: 0 10px;
  }

  #menu-footer li{
    display: block;
    padding: 15px 0;
    border-left: 0;
  }

  .slides .owl-nav{display: none;}
}

@media all and (max-width: 400px){
  .view-produits .views-row{width: 100%;}
  .view-produits .views-row:nth-child(even){
    border-left: 2px solid #000;}
  .home-criteres .item.contentbloc{
    max-width: 100%;
    width: 100%;
  }
}
